.group-card{
  .card{
    background: #f8f8fb;
    border-radius: 10px 10px 10px 10px;
    // box-shadow: 4px 4px 6px #a2a5b9;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);

  }
  .flaticon-logout:before{
    font-weight: bold;
  }
}
.group-email-list{
  height: 25vh;
  overflow: auto;
}
.ps__rail-y {
  opacity: 0.6 !important;
}