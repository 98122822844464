.hmenu-item-mobile{
  display: none !important;
}
.hmenu-item-mobile-icon {
  i {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .hmenu-item-mobile{
    display: flex !important;
  }
  .hmenu-item-mobile-icon {
    i {
      display: flex !important;
    }
  }
}
