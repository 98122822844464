
.error-404 {
  height: 100%;
  display: flex;
  justify-content: center;

  .body-container {
    display: flex;
    margin-bottom: 5rem;
    img {
      width: 100%;
      max-width: 600px;
      height: auto;
    }
  }
}
