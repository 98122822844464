@media(max-width: 576px) {
  .home-calendar.month, .home-calendar.loading-month {
    height: 55em;
  }
  .home-calendar.agenda {
    height: calc(100vh - 165px);
  }
}

@media(max-width: 1024px) {
  .home-calendar.month, .home-calendar.loading-month {
    height: 55em;
  }
  .home-calendar.agenda {
    height: calc(100vh - 165px);
  }
}

@media screen and (min-width: 1024px) {
  .home-calendar.agenda {
    height: calc(100vh - 165px);
  }
}

@media screen and (max-width: 450px) {
  .home-calendar {
    .rbc-btn-group {
      padding: 3px 45px;
    }
  }
}

.event_action span {
  padding: 3px 5px;
  cursor: pointer;
  color: kt-get($kt-base-colors, label, 1);
  font-size: 14px;
  .MuiBadge-badge{
    color: #ffffff;
  }
}
.event_action span:hover {
  color: kt-state-color(dark);
}

.share_modal .modal-body{
  height: 46vh;
  overflow: auto;
}
.calendar-update-height{
  .shared-emails{
    height: 24.5rem;
    overflow: auto;
  }
  .email-error{
    height: 14.5rem;
  }
}

.shared-emails{
  height: 18.5rem;
  overflow: auto;
}
.email-error{
  height: 8.5rem;
}

.calender_modal .modal-body{
  max-height: 80vh;
  overflow: auto;
}

.home-calendar {
  .rbc-toolbar {
    font-size: 13px;
    color: #48465b;
    .rbc-toolbar-label{
      color: #422798;
      font-weight: 500;
      font-size: 15.7px;

    }
    button {
      color: #48465b;
      border-color: #7e84f2;
      &.rbc-active {
        background-color: #7e84f2;
        border-color: #7e84f2;
        color: white;
      }
      &:hover {
        background-color: #7e84f2;
        color: white;
      }
    }
  }
  .rbc-event {
    background-color: transparent;
    color: #000000;
  }
  .rbc-header {
    font-weight: 500;
    color: #48465b;
  }
}
