.hmenu-header{
  display: none !important;
}
.hmenu-signout {
  display: none !important;
}
.hmenu-logo {
  display: none !important;
}
.hmenu-notifcation {
  display: none !important;
}
@media (max-width: 1024px) {
  .kt-menu__nav {
    padding-top: 10px !important;
  }
  .hmenu-header {
    display: flex !important;
    justify-content: center !important;
    position: fixed;
    .kt-header__topbar-item {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }
  .hmenu-signout {
    margin-bottom: 30px;
    display: flex !important;
    justify-content: center !important;
    position: fixed !important;
    bottom: 0 !important;
  }
  .hmenu-logo {
    height: 80px;
    margin-top: 30px;
    clear: both;
    padding: 0 !important;
    bottom: 20px !important;
    display: block !important;
    text-align: center;
    span {
      position: absolute;
      padding-left: 72px;
      float: left;
      opacity: 0.7;
      display: block;
      clear: both;
      margin: auto;
      font-size: 10px;
    }
    img {
      padding-left: 20px;
      float: left;
      margin: auto;
      margin-top: 5px;
      display: block;
      clear: both;
      width: auto;
      height: 50px;
    }
  }
  .hmenu-notifcation {
    display: block !important;
    .dropdown-menu-fit {
      transform: translate3d(0px, 43px, 0px) !important;
      max-width: -webkit-fill-available !important;
    }
  }
}
