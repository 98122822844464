.zen-profile-container {
  height: calc(100vh - 24em);
}
.zen-profile {
  overflow: auto;
  height: calc(100vh - 165px);
  position: relative;
  top: -100px;
  .card{
    padding: 0.7692rem 2.3078rem 0px 2.3078rem;
  }
  .MuiOutlinedInput-root{
    border-radius: 25px;
  }
  .kt-portlet{
    -webkit-box-shadow: 10px 10px 10px -10px rgb(105,105,105);
    -moz-box-shadow: 10px 10px 10px -10px rgb(105,105,105);
  }
  @media (min-width: 768px){
    .kt-portlet .kt-portlet__head {
      padding: 0px;
    }
  }
  @media (max-width: 768px) {
    .card{
      padding: 0rem;
    }
  }
}

.img-margin{
  margin-top: 1.5385rem;
}

.email-warning-icon {
  font-size: 1.5em;
}

.additional-emails-chk-box {
  margin-bottom: 1.5em;
  .MuiFormControlLabel-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.telegram-chk-box {
  margin-left: 8.9em !important;
}

#kt_content {
  .kt-subheader {
    height: 100px;
  }
}

@media(max-width: 768px) {
  .zen-profile{
    height: calc(100vh - 200px);
    top: -70px;
    padding: 0 40px;
  }
  .kt-subheader {
    margin-top: 0px;
    height: 110px;
  }
  .telegram-chk-box {
    margin-left: 0 !important;
  }
}

@media(min-height: 1280px) {
  .zen-profile{
    height: auto;
  }
}
