.kt-portlet {
  background: transparent !important;
  box-shadow: initial !important;
}

.calendar-no-data{
  height: 100%;
  background: white;
  span{
    font-size: 1.3rem;
    font-weight: 500;
  }
  img{
    width: 100%;
    max-width: 370px;
    height: auto;
  }
  .btn-blue-shade-6{
    box-shadow: 4px 4px 10px kt-get($kt-base-colors, label, 1) !important;
    border: 1px solid #ffffff !important;
  }
  .btn{
    &:focus{
      box-shadow: 4px 4px 10px kt-get($kt-base-colors, label, 1) !important;
    }
  }
}

.agenda-desktop-view{
  display: flex;
}
.agenda-mobile-view{
  display:none;
}


@mixin span-circle{
  content: '';
  height: 1.3rem;
  align-self: center;
  width: 1.3rem;
  background-color: kt-state-color(blue-shade-6);
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
}

.circle-color{
  @include span-circle;
}

.calendar-form-dailog{
  .MuiDialogTitle-root, .MuiTabs-root{
    padding-right: 2.3078rem;
    padding-left: 2.3078rem;
  }
  .MuiDialogContent-dividers{
    padding: 0px;
  }
}

.home_cal {
  margin-bottom: 0;
  // Customise toolbar
  .agenda_view {
    width: 100%;
    .home-calendar {
      padding: 0 30px;
    }
    .home-calendar.rbc-calendar .cal_toolbar{
      padding: 25px 30px 60px 30px;
    }
  }
  .view_options {
    width: 40%;
    margin: 0 auto;
    background-color: kt-state-color(light-blue);
    border-radius: 1.25em;
    color: kt-state-color(light);
    .cal_view_btn {
      background: transparent;
      cursor: pointer;
      line-height: 1;
      padding: 0.625em;
      font-size: 1em;
    }
    .cal_view_btn:hover, .cal_view_btn.active {
      background: kt-state-color(light);
      color: kt-state-color(dark);
    }
  }

  .cal_today {
    width: 100px;
  }
  .add_event_btn {
    width: 180px;
  }
  .row_2 {
    color: kt-state-color(light);
    display: flex;
  }
  .date_range {
    text-align: center;
    font-size: 1.3em;
    font-weight: 400;
    margin-top: 0.4em;
  }
  .add_event_btn, .cal_today {
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    span {
      display: inline-block;
      font-size: 1.4em;
      vertical-align: middle;
      margin: 0 0.625em 0 0;
      line-height: 1;
    }
  }
  .zen-cal-m-t{
    margin-top: 0.27rem;
  }

  // style for agenda view table
  .home-calendar.rbc-calendar .cal_toolbar{
    background-color: kt-state-color(blue-shade-6);
    padding: 25px 25px 20px 25px;
    margin-left: -30px;
    margin-right: -30px;
    border-radius: 0 0 25px 25px;
  }
  .rbc-agenda-view {
    position: relative;
    top: -35px;
    .rbc-agenda-empty {
      height: 100%;
    }
    .rbc-agenda-table {
      border: none !important;
      thead {
        display: none;
      }
      td {
        vertical-align: middle !important;
        color: kt-state-color(dark);
        font-size: 1.3em;
        background-color: kt-state-color(light);
      }
      tbody > tr > td {
        border-bottom: 0.625em solid kt-state-color(light-grey);
        padding: 1.25em;
        font-weight: 400;
      }
      tr:first-child td:first-child {
        border-radius: 0.625em 0 0 0;
      }
      td.rbc-agenda-time-cell {
        color: grey;
      }
      td.rbc-agenda-time-cell, .rbc-agenda-event-cell {
        border-left: none !important;
      }
      td.rbc-agenda-date-cell, td.rbc-agenda-time-cell {
        width: 20%;
        position: relative;
      }
      td.rbc-agenda-date-cell:before {
        content: '';
        background: url('/media/icons/calender.png');
        height: 2.5em;
        width: 2.5em;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1.25em;
        background-size: 100%;
      }

      td.rbc-agenda-date-cell:after {
        content: '';
        position: absolute;
        left: 0;
        height: 100%;
        top: 0;
        border-radius: 0.3125em 0 0 0.3125em;
        width: 7px;
        background-color: kt-state-color(success);
      }
    }

    .rbc-agenda-time-cell:before {
      content: '';
      background: url('/media/icons/clock.png');
      height: 2.5em;
      width: 2.5em;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.25em;
      background-size: 100%;
    }
  }
  .page_nav.agenda_toolbar {
    .btn {
      min-width: 8em;
    }
  }

  .rbc-month-view, .rbc-time-view {
    background: kt-state-color(light);
    margin-top: 6px;
    box-shadow: 4px 4px 10px kt-get($kt-base-colors, label, 1);
  }

  // Customise calendar view start
  .home-calendar .rbc-header {
    font-weight: 500;
    color: kt-get($kt-base-colors, label, 4);
    padding-top: 10px;
    padding-bottom: 10px;
    background: kt-get($kt-base-colors, grey, 1);
    font-size: 14px;
    text-transform: capitalize;
  }

  .event_container {
    border-radius: 40px 0 0 0;
  }
  .calendar_view {
    width: 80%;
    padding-right: 0px;
    .home-calendar {
      padding: 0 30px;
      .cal_toolbar{
        padding: 25px 30px 20px 30px;
      }
    }
    .page_nav {
      label {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }
      .month_txt {
        font-size: 20px;
        margin-right: 0.385rem;
        font-weight: 400;
        width: 13.3rem;
        text-align: center;
      }
      .nav_month {
        padding: 0 10px;
        line-height: 1;
        margin-right: 5px;
        cursor: pointer;
        font-size: 28px;
        margin-top: 2px;
      }
    }
  }

  .event_container {
    padding-left: 0px !important;
    .event_list {
      background: kt-state-color(light);
      height: calc(100vh - 150px);
      border-radius: 25px 0 0 0;
    }
  }

  .event_date, .event_heading {
    display: inline-block;
    vertical-align: middle;
  }

  .event_date {
    width: 4.5em;
    height: 5.51em;
    background: url(/media/icons/empty-cal.png) no-repeat;
    background-size: 100% 100%;
    padding: 12px 0 6px 0;
    text-align: center;
    font-weight: 500;
    .event_month {
      font-size: 16px;
      display: block;
      line-height: 1;
      background: kt-state-color(dark-blue);
      margin-top: 8px;
      padding: 2px;
      border-radius: 2px;
      color: kt-state-color(light);
    }
    .event_dt {
      font-size: 28px;
      color: kt-state-color(light);
      display: block;
      line-height: 1.1;
    }
  }

  .event_heading {
    font-size: 22px;
    color: kt-state-color(dark);
    font-weight: 600;
    margin-left: 1em;
  }

  .event_head_bg {
    background: kt-state-color(blue-shade-6);
    .event_header {
      background: kt-state-color(light);
      padding: 10px 10px 0px 20px;
      border-radius: 30px 0 0;
    }
  }

  .event_item {
    background: kt-state-color(light-grey);
    padding: 15px 10px;
    border-left: 10px solid kt-state-color(success);
    border-radius: 10px 0 0 10px;
    margin-bottom: 20px;
    box-shadow: 4px 4px 6px kt-get($kt-base-colors, label, 1);;
  }

  .event_body {
    margin-top: 20px;
    padding: 0 10px;
    max-height: calc(100% - 200px);
    overflow: auto;
  }

  .reminder-icon {
    width: 4em;
    height: 4em;
    background: url(/media/icons/reminder.png) no-repeat;
    background-size: 100%;
  }
  .event_desc {
    color: kt-state-color(dark);
    font-size: 16px;
    font-weight: 400;
  }
  .sub_desc {
    font-size: 14px;
    color: kt-get($kt-base-colors, label, 3);
  }
  .desc_row {
    padding-left: 10px;
  }

  .event_action span {
    padding: 3px 5px;
    cursor: pointer;
    color: kt-get($kt-base-colors, label, 1);
    font-size: 14px;
    .MuiBadge-badge{
      color: #ffffff;
    }
  }
  .event_action span:hover {
    color: kt-state-color(dark);
  }
  .rbc-show-more {
    display: inline !important;
  }
}

@include kt-tablet-and-mobile() {
  .home_cal {
    .calendar_view, .event_container {
      min-width: 100%;
      padding-left: 0;
      .event_body {
        height: auto;
      }
      .event_list {
        height: auto;
      }
    }
    .event_header {
      border-radius: 0 !important;
    }
    .agenda_view {
      .row_2 {
        display: block;
        text-align: center;
        .date_range {
          margin: 20px 0;
        }
      }
    }
    .event_date{
      width: 5.5em;
      height: 7em;
      padding: 12px 0 6px 0;
    }
  }
}


@include kt-mobile() {
  .calendar-no-data{
    span{
      font-size: 1rem;
    }
    img{
      max-width: 200px;
    }
  }
  .calendar_view {
    .row_2 {
      flex-direction: column;
      align-items: center;
      .page_nav {
        text-align: center;
        margin: 0 0 10px 10px;
      }
    }
  }
  .home_cal{
    .zen-cal-m-t{
      margin-top: 0rem;
    }
    .kt-portlet__body{
      padding: 0px;
    }
    .circle-color{
      width: 1.7rem;
    }
    .calendar_view .home-calendar,
    .agenda_view .home-calendar{
      padding: 0 10px;
    }
    .agenda_view .home-calendar.rbc-calendar .cal_toolbar {
      padding: 25px 0px 60px 0px;
    }
    .calendar_view .home-calendar.rbc-calendar .cal_toolbar {
      padding: 25px 15px 20px 15px;
    }
    .rbc-agenda-view {
      .rbc-agenda-table{
        td{
          padding: 19.5px 5px 19.5px 12px !important;
        }
        td.rbc-agenda-date-cell::before,
        td.rbc-agenda-time-cell{
          display: none;
        }
      }
    }
    .home-calendar.rbc-calendar .cal_toolbar{
      margin: 0px;
    }
  }
  .agenda-desktop-view{
    display: none;
  }
  .agenda-mobile-view{
    display: block;
  }
}

.styles_skeleton__2Mj1U {
  margin-bottom: 10px;
}
.loading-month .rbc-month-view {
  opacity: 0.5;
}
