.MuiListItem-root {
  img {
    display: none;
  }
}
.color-box{
  .MuiSelect-outlined.MuiSelect-outlined{
    padding: 0.5em 2em 0.5em 0.5em;
  }
}
.calendar-form-height{
  overflow: hidden !important;
  padding-right: 2.3078rem;
  padding-left: 2.3078rem;
  height: 38.7rem;
  overflow: auto;
  &.update-height-end-date {
    height: 44.5rem;
  }
  &.create-height-end-date{
    height: 44.5rem;
  }
  &.shared-height {
    height: 32rem;
  }
  &.shared-height-end-date {
    height: 40.5rem;
  }
  &.create-height-custom-recurrence {
    height: 50.0rem;
  }
  &.update-height-custom-recurrence {
    height: 50.0rem;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
    padding: 7.5px 4px;
  }
  .MuiOutlinedInput-input{
    padding: 16.5px 14px;
  }
  .MuiFormControl-marginNormal{
    margin-top: 14px;
    margin-bottom: 6px;
  }
}

.dropdown-button {
  display: none !important;
  .form-dropdown-button {
    margin-right: 2.3078rem !important;
    &.dropdown-menu {
      min-width: 7rem;
    }
  }
}
.calendar-form-row{
  width: 50% !important;
  &.pad-r{
    padding-right: 0.25rem !important;
  }
  &.pad-l{
    padding-left: 0.25rem !important;
  }
}

@media screen and (max-width: 400px) {
  .calendar-form-height {
    button {
      font-size: 0.9rem;
      padding: 0.65rem 0.7rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .hide-button {
    display: none !important;
  }
  .dropdown-button {
    display: inline-flex !important;
  }
  .calendar-form-height {
    overflow: auto !important;
  }
  .calendar-form-row {
    width: 100% !important;
    &.pad-r{
      padding-right: 0rem !important;
    }
    &.pad-l{
      padding-left: 0rem !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .calendar-form-height{
    overflow: auto !important;
  }
}

@media screen and (min-width: 2000px) {
  .calendar-form-height{
    max-height: 54rem;
  }
}
