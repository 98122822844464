@media (max-width: 1024px) {
  .user-notification-mob {
    cursor: pointer;
    display: flex !important;
    padding: 9px 30px;
    .notification-icon-mob {
      color: #a8aabb !important;
      flex: 0 0 35px;
    }
    i {
      font-size: 1.4rem;
    }
    .notification-text-mob {
      display: flex;
      align-items: center;
      color: #5e6383;
      font-weight: 400;
      font-size: 1.02rem;
    }
  }
}

.kt-icon-size-xs {
  font-size: 0.5rem;
}
