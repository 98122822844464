.zen-invite-page{
  top: -50px;
  margin: 10px;
  height: calc(100vh - 300px);
  .kt-portlet .kt-portlet__body {
    padding: 10px 30px;
    height: 100%;
  }
  .table-responsive{
    height: calc(100% - 50px);
    thead th {
      background-color: white;
      position: sticky;
      top: 0;
    }
    td {
      vertical-align: middle;
    }
  }
  @media (max-width: 1024px) {
    .kt-portlet .kt-portlet__body {
      padding: 15px;
    }
  }
}
