
.unsubscribe-email {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .body-container {
    margin: 0 10%;
    text-align: center;

    .image-container {
      margin-top: 10%;
    }

    .sub-title {
      h3 {
        margin-top: 5%;
        font-size: 3rem;
        font-weight: 700;
      }
    }

    .description {
      margin-top: 3rem;
      font-size: 2.3rem;
      font-weight: 500;
      line-height: 3rem;
    }
  }
}

