.zen-auth-page {
	background-size: cover !important;
	background-position: center !important;
	height: 100%;
	background-repeat: no-repeat !important;
	background-attachment: fixed;
	overflow: auto;
	.app-store-badge{
		height: 40px;
		width: 100px;
		margin-left: 10px;
		g{
			fill: black;
		}
	}
	.google-pay-badge{
		margin-right: 10px;
		height: 33px;
		width: 100px;
	}
	.card{
		border-radius: 0px;
	}
	.btn-outline-dark{
		color: kt-state-color(primary);
		font-weight: 500;
		&:hover{
			color: kt-state-color(light);
		}
	}
	button{
		h4{
			margin: 0.407rem 0rem;
		}
	}
	.alert{
		margin: 0px;
	}
	.alert.alert-success{
		background-color: #E5FFFD;
		border-color: #E5FFFD;
		.alert-text{
			color: #0C7960;
		}
	}
}
.zen-custom-button{
	button{
		font-size: 1.2rem;
		padding: 1.056rem 1rem;
	}
}
.zen-card-shadow{
	padding: 2rem;
	box-shadow: 3px 3px 30px 4px #0000006b;
}
.zen-auth-content{
	flex: 1 0 auto;
	width: 30rem;
}

.zen-auth-footer{
	flex-shrink: 0;
	width: 30rem;
}

@media (max-width: 450px) {
	.zen-auth-content,
	.zen-auth-footer{
		width: 24rem;
	}
	h3{
		font-size: 1.68rem;
	}
}

@include kt-mobile-sm() {
	.zen-auth-page {
		background: kt-state-color(primary) !important;
	}
}
