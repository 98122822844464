//
// Layout Config
// Here you can set the demo level SASS variables and override the global variables for this demo.
// Note: To override the global variables in the demo level use the variables without "!default". More info:  https://sass-lang.com/documentation/variables
//




// State colors
$kt-state-colors: (
    // Metronic states
    brand: (
        base: #6146d8,
        inverse: #ffffff
    ),
    light: (
        base: #ffffff,
        inverse: #282a3c
    ),
    dark: (
        base: #282a3c,
        inverse: #ffffff
    ),

    // Bootstrap states
    primary: (
        base: #6146d8,
        inverse: #ffffff
    ),
    secondary: (
        base: #c2eaee,
        inverse: #000000
    ),
    success: (
        base: #00a99d,
        inverse: #ffffff
    ),
    info: (
        base: #662d91,
        inverse: #ffffff
    ),
    warning: (
        base: #f3d95c,
        inverse: #111111
    ),
    danger: (
        base: #e6494e,
        inverse: #ffffff
    ),
    orangish: (
        base: #f57d58,
        inverse: #ffffff
    ),
    purple-color: (
        base: #992b7b,
        inverse: #ffffff
    ),
    green-shade-1: (
        base: #f3fafb,
        inverse: #000000
    ),
    green-shade-2: (
        base: #dcf2f4,
        inverse: #000000
    ),
    green-shade-3: (
        base: #9ddee4,
        inverse: #000000
    ),
    green-shade-4: (
        base: #74d2db,
        inverse: #000000
    ),
    green-shade-5: (
        base: #70c1de,
        inverse: #000000
    ),
    green-shade-6: (
        base: #77ace5,
        inverse: #000000
    ),
    green-shade-7: (
        base: #0076a3,
        inverse: #ffffff
    ),
    blue-shade-1: (
        base: #7d98ec,
        inverse: #ffffff
    ),
    blue-shade-2: (
        base: #7e84f2,
        inverse: #ffffff
    ),
    blue-shade-3: (
        base: #7e6cfa,
        inverse: #ffffff
    ),
    blue-shade-4: (
        base: #7354f3,
        inverse: #ffffff
    ),
    blue-shade-5: (
        base: #5038bb,
        inverse: #ffffff
    ),
    blue-shade-6: (
        base: #422798,
        inverse: #ffffff
    ),
    blue-shade-7: (
        base: #3f2da4,
        inverse: #ffffff
    ),
    light-blue: (
        base: #7f73bb,
        inverse: #ffffff
    ),
    dark-blue: (
        base: #1a0956,
        inverse: #ffffff
    ),
    light-grey: (
        base: #f8f8fb,
        inverse: #ffffff
    )
);

// Layout
$kt-aside-default-width: 245px;
$kt-aside-offcanvas-width: 255px;

// Page padding
$kt-page-padding: (
	desktop: 30px,
	mobile: 15px
);

// Page container
$kt-page-container-width: 1380px; //  fixed page width
$kt-page-fluid-container-padding: 60px; // fluid page padding

// Body color
$kt-page-body-bg-color: #f9f9fc;  // page body bg color

// Subheader config
$kt-subheader: (
	toggle: (
		width: 20px,
		height: 22px,
		transition-duration: 0.4s,
		thickness: 2px,
		space: 4px,
		radius: 2px,
		color: (
			default: rgba(#fff, 0.7),
			hover: kt-brand-color(),
			active: kt-brand-color()
		)
	)
);
