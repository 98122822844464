.terms_body {
  font-size: 9px;
  max-height: 85vh;
  overflow: auto;
  text-align: justify;
}

.form-select {
  width: 53%;
}

@media (max-width: 575px) {
  .terms_body {
    max-height: 91vh;
  }
  .form-select {
    width: 100%;
  }
}
